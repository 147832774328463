import { FC, PropsWithChildren, useMemo, useState } from "react";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  Typography,
} from "@mui/material";
import { sortBy } from "lodash";

import {
  AnimalDiagnosticData,
  useAnimalDiagnostics,
} from "../../hooks/animal/use-diagnostics";
import { formatDate } from "../Utils";
import { QuickSearchToolbar } from "../Common/QuickSearchToolbarReadings";
import { More } from "@mui/icons-material";

export interface ImplantDiagnosticsProps {
  animalId: string;
}

export const logColumns: GridColDef[] = [
  {
    field: "operation",
    headerName: "Operation",
    type: "string",
    align: "left",
    headerAlign: "left",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {params.value ?? "poll-readings"}
        </Typography>
      );
    },
  },
  {
    field: "platform",
    headerName: "Platform",
    type: "string",
    align: "left",
    headerAlign: "left",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: "firmware",
    headerName: "Firmware",
    type: "string",
    align: "left",
    headerAlign: "left",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: "startTime",
    headerName: "Start Time",
    type: "string",
    align: "left",
    headerAlign: "left",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {formatDate(params.value)}
        </Typography>
      );
    },
  },
  {
    field: "endTime",
    headerName: "End Time",
    type: "string",
    align: "left",
    headerAlign: "left",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {formatDate(params.value)}
        </Typography>
      );
    },
  },
  {
    field: "didConnect",
    headerName: "Connected",
    type: "string",
    align: "left",
    headerAlign: "left",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {params.value ? "true" : "false"}
        </Typography>
      );
    },
  },
  {
    field: "blobCount",
    headerName: "Blobs",
    type: "string",
    align: "left",
    headerAlign: "left",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {params.value}
        </Typography>
      );
    },
  },
  {
    field: "readings",
    headerName: "Readings",
    type: "string",
    align: "left",
    headerAlign: "left",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => {
      return (
        <Typography variant="body2" sx={{ cursor: "pointer" }}>
          {params.value}
        </Typography>
      );
    },
  },
];

export const ImplantDiagnostics: FC<ImplantDiagnosticsProps> = ({
  animalId,
}) => {
  const { loading, more, refresh, diagnostics } =
    useAnimalDiagnostics(animalId);

  const [visible, setVisible] = useState<AnimalDiagnosticData>();

  const rows = useMemo(
    () =>
      diagnostics?.map((d) => ({
        operation: d.metrics.operation,
        startTime: d.startTime,
        endTime: d.endTime,
        firmware: d.metrics.firmwareVersion,
        didConnect: d.metrics.bleConnected,
        platform: d.metrics.platform,
        blobCount: d.metrics.blob_segment_count,
        readings: d.metrics.new_readings,
        data: d,
      })),
    [diagnostics]
  );

  if (loading) {
    return <CircularProgress />;
  }

  if (!rows?.length) {
    return <p>No diagnostic data available...</p>;
  }

  return (
    <>
      <DataGrid
        columns={logColumns}
        getRowId={(d) => `${d.startTime} - ${d.endTime}`}
        rows={rows}
        onRowClick={(params) => {
          setVisible(params.row.data);
        }}
        components={{ Toolbar: QuickSearchToolbar }}
        componentsProps={{
          toolbar: {
            onReload: () => refresh(),
            buttons: (
              <>
                <GridToolbarContainer sx={{ flex: 2 }}>
                  <Button
                    size="small"
                    onClick={async () => {
                      await more();
                    }}
                    aria-label="Load More Rows"
                    startIcon={<More />}
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    Load More
                  </Button>
                </GridToolbarContainer>
              </>
            ),
          },
        }}
      />
      <Dialog
        open={!!visible}
        onClose={() => setVisible(undefined)}
        fullWidth
        maxWidth={false}
      >
        <DialogTitle>
          {animalId} - Diagnostic - {formatDate(visible?.startTime)} -{" "}
          {formatDate(visible?.endTime)}
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="row">
            <Box flex={1} marginRight="1rem">
              <Typography marginBottom={1}>Details</Typography>
              <Box maxHeight={"calc(100vh - 400px)"} overflow="auto">
                <DiagnosticMetricList metrics={visible?.metrics ?? {}} />
              </Box>
            </Box>
            <Box flex="1">
              <Typography marginBottom={1}>
                Logs ({visible?.logs.length})
              </Typography>
              <Box maxHeight={"calc(100vh - 400px)"} overflow="auto">
                {visible?.logs?.map((log) => (
                  <Box
                    color={
                      log.startsWith("Error:")
                        ? "#f00"
                        : log.startsWith("Warn:") || log.startsWith("Warning:")
                        ? "#f82"
                        : log.startsWith("Info:")
                        ? "#00f"
                        : "#000"
                    }
                  >
                    {log}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          {!!visible?.blob && (
            <Button
              component={Link}
              href={URL.createObjectURL(
                new Blob([visible.blob], { type: "application/octet-stream" })
              )}
              download={`${visible.implantId}-${visible.startTime}.hex`}
            >
              Download Blob
            </Button>
          )}
          <Button onClick={() => setVisible(undefined)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const knownServices: Record<string, string> = {
  "1800": "Generic Access Service",
  "00001800-0000-1000-8000-00805f9b34fb": "Generic Access Service",

  "1801": "Generic Attribute Service",
  "00001801-0000-1000-8000-00805f9b34fb": "Generic Attribute Service",

  "8d53dc1d-1db7-4cd3-868b-8a527460aa84": "MCU Update Service",
  "8d53dc1d-1db7-4cd3-868b-8a527460aa84/da2e7828-fbce-4e01-ae9e-261174997c48":
    "MCU Update Characteristic",

  "180f": "Battery Service",
  "180f/2a19": "Battery Characteristic",
  "0000180f-0000-1000-8000-00805f9b34fb": "Battery Service",
  "0000180f-0000-1000-8000-00805f9b34fb/00002a19-0000-1000-8000-00805f9b34fb":
    "Battery Characteristic",

  "180d": "Heartrate Service",
  "180d/2a37": "Heartrate Characteristic",
  "0000180d-0000-1000-8000-00805f9b34fb": "Heartrate Service",
  "0000180d-0000-1000-8000-00805f9b34fb/00002a37-0000-1000-8000-00805f9b34fb":
    "Heartrate Characteristic",

  "00001822-0000-1000-8000-00805f9b34fb": "Pulse Oximeter Service",
  "00001822-0000-1000-8000-00805f9b34fb/00002a5e-0000-1000-8000-00805f9b34fb":
    "Pulse Oximeter Characteristic",
  "00001822-0000-1000-8000-00805f9b34fb/00002a5f-0000-1000-8000-00805f9b34fb":
    "Pulse Oximeter (Continuous) Characteristic",

  "1809": "Temperature Service",
  "1809/2a1c": "Temperature Characteristic",
  "00001809-0000-1000-8000-00805f9b34fb": "Temperature Service",
  "00001809-0000-1000-8000-00805f9b34fb/00002a1c-0000-1000-8000-00805f9b34fb":
    "Temperature Characteristic",

  "00dae901-04d4-4725-9fa8-0640be296d41": "VetChip Config Service",
  "00dae901-04d4-4725-9fa8-0640be296d41/00dae907-04d4-4725-9fa8-0640be296d41":
    "Firmware Version Characteristic",

  "00dae901-a496-4e19-87da-8f7b76c96a44": "VetChip Utility Service",
  "00dae901-a496-4e19-87da-8f7b76c96a44/00dae902-a496-4e19-87da-8f7b76c96a44":
    "Implant Time Characteristic",
  "00dae901-a496-4e19-87da-8f7b76c96a44/00dae906-a496-4e19-87da-8f7b76c96a44":
    "TempSense Wakeup Characteristic",
  "00dae901-a496-4e19-87da-8f7b76c96a44/00dae903-a496-4e19-87da-8f7b76c96a44":
    "Sleep / ShipMode Characteristic",

  "00dae901-b73e-4203-92d6-fd56431ca9e9": "VetChip Blob Service",
  "00dae901-b73e-4203-92d6-fd56431ca9e9/00dae902-b73e-4203-92d6-fd56431ca9e9":
    "VetChip Blob Characteristic",

  "00dae901-04d4-641b-413b-e80a4117f2d8": "VetChip Data/Streaming Service",
  "00dae90b-04d4-641b-413b-e80a4117f2d8": "File Transfer Characteristic",
  "00dae90c-04d4-641b-413b-e80a4117f2d8": "Streaming Characteristic",

  "00dae901-bdd1-4121-a929-9cd0724f578c": "VetChip Action Service",
  "00dae902-bdd1-4121-a929-9cd0724f578c": "Action Characteristic",

  "00dae901-8a49-4dd5-bc42-f159bfa1be87": "VetChip Config Service (protobuf)",
  "00dae902-8a49-4dd5-bc42-f159bfa1be87": "Config Characteristic",
};

export const DiagnosticMetricList: FC<{ metrics: Record<string, any> }> = ({
  metrics,
}) => {
  const {
    operation = "poll-readings",
    bleConnected,
    platform,

    hardwareId,
    hardwareName,
    hardwareSettings,
    hardwareVersion,
    hardwareFlags,
    firmwareVersion,

    epochTime,
    updatedEpochTime,

    blob_segment_count = 0,
    logMsg_count = 0,
    total_reading_count = 0,
    temperature_count = 0,
    batteryPercent_count = 0,
    implant_reading_count = 0,
    heartRate_count = 0,
    heartRateVariance_count = 0,
    new_readings = 0,

    passive_polling,

    bleServices,
    phoneFeatures,
    ...otherMetrics
  } = metrics;
  return (
    <>
      <DiagnosticMetric name={"Operation"}>{operation}</DiagnosticMetric>
      <DiagnosticMetric name={"Platform"}>{platform}</DiagnosticMetric>
      {operation === "poll-readings" && (
        <>
          <DiagnosticMetric name={"Passive Polling"}>
            {passive_polling ? "true" : "false"}
          </DiagnosticMetric>
          <DiagnosticMetric name={"BLE Connected"}>
            {bleConnected ? (
              "true"
            ) : (
              <Typography color="error">false</Typography>
            )}
          </DiagnosticMetric>
        </>
      )}
      <Divider sx={{ marginY: 2 }} />
      <DiagnosticMetric name={"hardware ID"}>{hardwareId}</DiagnosticMetric>
      <DiagnosticMetric name={"hardware Name"}>{hardwareName}</DiagnosticMetric>
      <DiagnosticMetric name={"Hardware Version"}>
        {hardwareVersion}
      </DiagnosticMetric>
      <DiagnosticMetric name={"Hardware Flags"}>
        {JSON.stringify(hardwareFlags)}
      </DiagnosticMetric>
      <DiagnosticMetric name={"Firmware"}>{firmwareVersion}</DiagnosticMetric>
      <DiagnosticMetric name={"Hardware Settings"}>
        {JSON.stringify(hardwareSettings)}
      </DiagnosticMetric>
      {bleConnected && (
        <>
          <Divider sx={{ marginY: 2 }} />
          <DiagnosticMetric name={"Implant Time"}>
            {epochTime ? (
              formatDate(new Date(epochTime * 1000))
            ) : (
              <Typography color="error">NOT SET</Typography>
            )}
          </DiagnosticMetric>
          <DiagnosticMetric name={"Implant Time (Updated)"}>
            {updatedEpochTime ? (
              formatDate(new Date(updatedEpochTime * 1000))
            ) : (
              <Typography color="error">NOT SET</Typography>
            )}
          </DiagnosticMetric>
          <Divider sx={{ marginY: 2 }} />
          <DiagnosticMetric name="Total Readings">
            {total_reading_count}
          </DiagnosticMetric>
          <DiagnosticMetric name="Blobs">{blob_segment_count}</DiagnosticMetric>
          <DiagnosticMetric name="Readings">
            {implant_reading_count}
          </DiagnosticMetric>
          <DiagnosticMetric name="New Readings">
            {new_readings}
          </DiagnosticMetric>
          <DiagnosticMetric name="Temperature Readings">
            {temperature_count}
          </DiagnosticMetric>
          <DiagnosticMetric name="Battery Readings">
            {batteryPercent_count}
          </DiagnosticMetric>
          <DiagnosticMetric name="Heart Rate Readings">
            {heartRate_count}
          </DiagnosticMetric>
          <DiagnosticMetric name="HRV Readings">
            {heartRateVariance_count}
          </DiagnosticMetric>
          <DiagnosticMetric name="Log Messagess">
            {logMsg_count}
          </DiagnosticMetric>
          <Divider sx={{ marginY: 2 }} />
          {!!bleServices && (
            <DiagnosticMetric name={"bleServices"}>
              {sortBy(Object.keys(bleServices ?? {})).map((serviceId) => (
                <>
                  <Box>
                    {knownServices[serviceId] && (
                      <span>{knownServices[serviceId]} &mdash; </span>
                    )}
                    {serviceId}
                  </Box>
                  {sortBy(bleServices[serviceId]).map((charId: string) => (
                    <Box paddingLeft={4} fontWeight={400}>
                      {knownServices[`${serviceId}/${charId}`] && (
                        <span>
                          {knownServices[`${serviceId}/${charId}`]} &mdash;{" "}
                        </span>
                      )}
                      {charId}
                    </Box>
                  ))}
                </>
              ))}
            </DiagnosticMetric>
          )}
          <Divider sx={{ marginY: 2 }} />
        </>
      )}

      {Object.keys(otherMetrics).map((m) => {
        const val = otherMetrics[m];
        const typ = typeof val;
        const raw = typ === "string" || typ === "number";
        return (
          <DiagnosticMetric name={m}>
            {raw ? val : JSON.stringify(val, null, 2)}
          </DiagnosticMetric>
        );
      })}
      {!!phoneFeatures && <Divider sx={{ marginY: 2 }} />}
      {!!phoneFeatures && (
        <DiagnosticMetric name={"phoneFeatures"}>
          {sortBy(phoneFeatures).map((feat: string) => (
            <Box key={feat} fontWeight={400}>
              {feat}
            </Box>
          ))}
        </DiagnosticMetric>
      )}
    </>
  );
};

export const DiagnosticMetric: FC<{ name: string } & PropsWithChildren> = ({
  name,
  children,
}) => {
  return (
    <Box display="flex" flexDirection="row" flex={1}>
      <Box textOverflow="ellipsis" overflow="none" flex="0 0 12rem">
        {name}
      </Box>
      <Box fontWeight={600} flex={1} marginLeft={1}>
        {children}
      </Box>
    </Box>
  );
};
